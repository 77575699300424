//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { STable, XCard } from '@/components'
import { sysDictDataPage, sysDictDataDelete } from '@/api/modular/system/dictDataManage'
import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'
import addForm from './addForm'
import editForm from './editForm'
export default {
  components: {
    XCard,
    STable,
    addForm,
    editForm
  },
  data () {
    return {
      // 查詢參數
      queryParam: {},
      // 表頭
      columns: [
        {
          title: '字典值',
          dataIndex: 'value'
        },
        {
          title: '唯一編碼',
          dataIndex: 'code'
        },
        {
          title: '排序',
          dataIndex: 'sort'
        },
        {
          title: '備註',
          dataIndex: 'remark',
          width: 200
        },
        {
          title: '狀態',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' }
        }
      ],
      visible: false,
      typeId: [],
      // 加載數據方法 必須為 Promise 對象
      loadData: parameter => {
        this.queryParam.typeId = this.typeId
        return sysDictDataPage(Object.assign(parameter, this.queryParam)).then((res) => {
          return res.data
        })
      },
      statusDict: []
    }
  },
  created () {
    this.sysDictTypeDropDown()
    if (this.hasPerm('sysDictData:edit') || this.hasPerm('sysDictData:delete')) {
      this.columns.push({
        title: '操作',
        width: '150px',
        dataIndex: 'action',
        scopedSlots: { customRender: 'action' }
      })
    }
  },
  methods: {
    // 打開此頁面首先加載此方法
    index (record) {
      this.visible = true
      this.typeId = record.id
      this.queryParam.typeId = record.id
      try {
        this.$refs.table.refresh()
      } catch (e) {
        // 首次進入界面，因表格加載順序，會拋異常，我們不予理會
      }
    },
    statusFilter (status) {
      // eslint-disable-next-line eqeqeq
      const values = this.statusDict.filter(item => item.code == status)
      if (values.length > 0) {
        return values[0].value
      }
    },
    /**
     * 獲取字典數據
     */
    sysDictTypeDropDown () {
      sysDictTypeDropDown({ code: 'common_status' }).then((res) => {
        this.statusDict = res.data
      })
    },
    handleCancel () {
      this.queryParam = {}
      this.visible = false
    },
    sysDictDataDelete (record) {
      sysDictDataDelete(record).then((res) => {
        if (res.success) {
          this.$message.success('刪除成功')
          this.$refs.table.refresh()
        } else {
          this.$message.error('刪除失敗：' + res.message)
        }
      }).catch((err) => {
        this.$message.error('刪除錯誤：' + err.message)
      })
    },
    handleOk () {
      this.$refs.table.refresh()
    }
  }
}
